import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link,
  makeStyles,
  Box,
  NoSsr,
  Button,
  CardActions,
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import useSWR from "swr";
import { AnswerEntity, FullEventEntity, RegistrationEntity } from "../entities";
import { urls } from "../urls";
import { useUser } from "../user";
import { emUrl } from "../util";
import NextLink from "next/link";

const useStyles = makeStyles(() => ({
  listBtn: {
    textAlign: "center",
  },
}));

export function MyRegistrations({
  isMobile,
  event,
  fetchAll,
  showDate,
  backTo,
}: {
  isMobile: boolean;
  event?: FullEventEntity;
  fetchAll?: boolean;
  showDate?: boolean;
  backTo: string;
}) {
  const user = useUser();
  const canShowRegistrations =
    user.loggedIn || user.authorizedEmailAddresses.length > 0;

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" component="h2">
          Meine Anmeldungen
          {event && (
            <>
              {" "}
              zu
              <br />
              <u>dieser</u> Veranstaltung
            </>
          )}
          {!canShowRegistrations && " ansehen"}
        </Typography>
      </CardContent>
      {user.permissions.TEACHER && (
        <Alert severity="error">
          Hier werden nur Anmeldungen angezeigt, die Sie selbst getätigt haben.{" "}
          {event ? (
            <>
              Um die Anmeldungen von anderen zu dieser Veranstaltung anzuzeigen,
              besuchen Sie bitte{" "}
              <Link href={emUrl("event", "registrations", { eid: event.eid })}>
                die Administrationsseite dieser Veranstaltung
              </Link>
              .
            </>
          ) : (
            <>
              Um die Anmeldungen zu Ihren Veranstaltungen anzuzeigen, besuchen
              Sie bitte{" "}
              <Link href={urls.myEvents}>
                die Administrationsseite ihrer Veranstaltung
              </Link>
              .
            </>
          )}
        </Alert>
      )}
      {canShowRegistrations ? (
        <RegistrationList
          isMobile={isMobile}
          event={event}
          fetchAll={fetchAll}
          showDate={showDate}
        />
      ) : (
        <LoginPrompt backTo={backTo} />
      )}
    </Card>
  );
}

function LoginPrompt({ backTo }: { backTo: string }) {
  return (
    <>
      <CardActions style={{ alignItems: "start" }}>
        <div>
          <span style={{ padding: "0.6rem" }}>ohne Passwort:</span>
          <NextLink
            href={{
              pathname: "/verify-email",
              query: { backTo },
            }}
            passHref
          >
            <Button
              variant="outlined"
              size="small"
              style={{ marginTop: "0.2rem", textTransform: "none" }}
            >
              über die E-Mail-Adresse, mit der ich mich angemeldet habe
            </Button>
          </NextLink>
        </div>
        <div>
          <span style={{ padding: "0.6rem" }}>mit Passwort:</span>
          <NextLink
            href={{
              pathname: "/login",
              query: { backTo },
            }}
            passHref
          >
            <Button
              variant="outlined"
              size="small"
              style={{ marginTop: "0.2rem", textTransform: "none" }}
            >
              über mein Benutzerkonto
            </Button>
          </NextLink>
        </div>
      </CardActions>
    </>
  );
}

function RegistrationList({
  isMobile,
  event,
  fetchAll,
  showDate,
}: {
  isMobile: boolean;
  event?: FullEventEntity;
  fetchAll?: boolean;
  showDate?: boolean;
}) {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const { data, error, revalidate, isValidating } = useSWR<{
    registrations: RegistrationEntity[];
  }>(
    emUrl("rest", "recentRegistrations", {
      eid: event?.eid,
      ...(fetchAll === true ? { onlyLastMonth: 0 } : {}),
    }),
    {
      refreshInterval: 20000,
    }
  );
  const canBeExpanded = data && data.registrations.length > 1;
  const { enqueueSnackbar } = useSnackbar();

  const user = useUser();
  const emailAddresses = user.isReady
    ? Array.from(
        new Set(
          [
            ...(user.email ? [user.email] : []),
            ...user.authorizedEmailAddresses,
          ].map((each) => each.trim())
        )
      )
    : [];

  return !data || error ? (
    error ? (
      <Alert severity="error">
        Anmeldungen konnten nicht geladen werden.{" "}
        <Button onClick={() => revalidate()} disabled={isValidating}>
          Nochmal versuchen
        </Button>
      </Alert>
    ) : (
      <>
        <Box pt={2} pb={2} px={2}>
          <Skeleton variant="rect" width={"100%"} height={60} />
        </Box>
        {!isMobile && (
          <>
            <Box pb={2} px={2}>
              <Skeleton variant="rect" width={"100%"} height={60} />
            </Box>
            <Box pb={2} px={2}>
              <Skeleton variant="rect" width={"100%"} height={60} />
            </Box>
          </>
        )}
      </>
    )
  ) : (
    <>
      {emailAddresses && (
        <Alert severity="warning">
          Es werden nur Anmeldungen unter den folgenden E-Mail-Adressen
          angezeigt: <em>{emailAddresses.join(", ")}</em>
        </Alert>
      )}
      {data.registrations.length === 0 && (
        <Alert severity="info">
          {event
            ? "Sie haben sich zu dieser Veranstaltung bisher nicht angemeldet."
            : "Sie haben sich in letzter Zeit zu keinen Veranstaltungen angemeldet."}
        </Alert>
      )}
      <List disablePadding dense>
        {data.registrations.map((registration, i) => {
          if (isMobile && !expanded && i > 0) {
            return null;
          }
          return (
            <Registration
              key={registration.uid}
              registration={registration}
              showEventTitle={!event}
              showDate={showDate === true}
            />
          );
        })}
        {isMobile && canBeExpanded && !expanded ? (
          <>
            <Alert severity="warning">
              {data.registrations.length - 1} Anmeldungen zu dieser
              Veranstaltung sind ausgeblendet.
            </Alert>
            <ListItem divider button onClick={() => setExpanded(true)}>
              <ListItemText
                primary="Weitere Anmeldungen einblenden"
                className={classes.listBtn}
              />
            </ListItem>
          </>
        ) : (
          <ListItem
            divider
            button
            disabled={isValidating}
            className={classes.listBtn}
            onClick={async () => {
              await revalidate();
              enqueueSnackbar("Liste aktualisiert", { variant: "success" });
            }}
          >
            <ListItemText primary={"Liste aktualisieren"} />
          </ListItem>
        )}
        {(!isMobile || !canBeExpanded || expanded) && !fetchAll && (
          <NextLink href="/registrations" passHref>
            <ListItem divider button component="a" className={classes.listBtn}>
              <ListItemText
                primary={
                  event
                    ? "Anmeldungen zu anderen Veranstaltungen anzeigen"
                    : "Ältere Anmeldungen anzeigen"
                }
              />
            </ListItem>
          </NextLink>
        )}
      </List>
    </>
  );
}

function Registration({
  registration,
  showEventTitle,
  showDate,
}: {
  registration: RegistrationEntity;
  showEventTitle: boolean;
  showDate: boolean;
}) {
  return (
    <ListItem
      divider
      button
      component="a"
      href={emUrl("user", "edit", {
        uid: registration.uid,
        editurl: registration.editToken,
        eid: registration.event.eid,
      })}
    >
      <ListItemText
        disableTypography
        primary={
          <>
            {showEventTitle && (
              <Typography variant="body1">
                {registration.event.title}
              </Typography>
            )}
            {showDate && (
              <Typography variant="body2" color="textSecondary">
                Anmeldedatum:{" "}
                <span style={{ fontWeight: "bold" }}>
                  <NoSsr>
                    {new Date(registration.createdAt * 1000).toLocaleString()}
                  </NoSsr>
                </span>
              </Typography>
            )}
            <Typography variant="body2" color="textSecondary" component="div">
              {registration.answers.map((answer) => (
                <Answer key={answer.aid} answer={answer} />
              ))}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
}

export function Answer({ answer }: { answer: AnswerEntity }) {
  return (
    <div>
      {answer.question.label}:{" "}
      <div
        style={{ display: "inline", fontWeight: "bold" }}
        dangerouslySetInnerHTML={{ __html: answer.html }}
      />
    </div>
  );
}
