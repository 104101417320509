import { Container } from "@material-ui/core";
import React from "react";
import hoistNonReactStatic from "hoist-non-react-statics";

export function DefaultLayout(props: {
  children: NonNullable<React.ReactNode>;
}) {
  return (
    <Container id="content-top" disableGutters>
      {props.children}
    </Container>
  );
}

export function FullWidthLayout(props: React.PropsWithChildren<{}>) {
  return <div id="content-top">{props.children}</div>;
}

export function withDefaultLayout<P>(WrappedComponent: React.FC<P>) {
  class WithDefaultLayout extends React.Component<P> {
    static displayName = `WithDefaultLayout(${
      WrappedComponent.displayName || WrappedComponent.name || "Component"
    })`;

    public render() {
      return (
        <DefaultLayout>
          <WrappedComponent {...this.props} />
        </DefaultLayout>
      );
    }
  }
  hoistNonReactStatic(WithDefaultLayout, WrappedComponent);
  return WithDefaultLayout;
}

export function withFullWidthLayout<P>(WrappedComponent: React.FC<P>) {
  class WithFullWidthLayout extends React.Component<P> {
    static displayName = `WithFullWidthLayout(${
      WrappedComponent.displayName || WrappedComponent.name || "Component"
    })`;

    public render() {
      return (
        <FullWidthLayout>
          <WrappedComponent {...this.props} />
        </FullWidthLayout>
      );
    }
  }
  hoistNonReactStatic(WithFullWidthLayout, WrappedComponent);
  return WithFullWidthLayout;
}
