export function generateBackTo(
  page: "r" | "s" | "e" | "rr",
  args: Record<string, string | number | boolean | null | undefined>
): string {
  const argString = Object.entries(args)
    .filter(
      ([key, value]) => value !== false && value !== null && value !== undefined
    )
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join(",");
  return `${page}` + (argString ? `,${argString}` : "");
}

export function parseBackTo(backTo: string | string[] | undefined): URL | null {
  const obj = _parseBackTo(backTo);
  if (!obj) {
    return null;
  }
  const url = new URL("", window.location.origin);

  if (obj.pathname) {
    url.pathname = obj.pathname;
  }
  if (obj.query) {
    Object.entries(obj.query).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        url.searchParams.set(key, value);
      }
    });
  }

  return url;
}

function _parseBackTo(backTo: string | string[] | undefined): {
  pathname: string;
  query?: Record<string, string | undefined | null>;
} | null {
  if (Array.isArray(backTo)) {
    backTo = backTo[0];
  }

  if (!backTo) {
    return null;
  }

  try {
    const parts = backTo.split(",");
    const page = parts[0];
    const args = Object.fromEntries(
      parts.slice(1).map((keyValue) => keyValue.split("="))
    );

    if (page === "s") {
      const cid = args.cid;

      if (typeof cid === "string") {
        return {
          pathname: `/category/${encodeURIComponent(cid)}`,
        };
      } else {
        return {
          pathname: "/",
        };
      }
    } else if (page === "rr") {
      return {
        pathname: "/registrations",
      };
    } else if (page === "e") {
      const eid = args.eid;
      if (typeof eid === "string") {
        return {
          pathname: `/e/${encodeURIComponent(eid)}`,
        };
      }
    } else if (page === "r") {
      const { eid, dpid, recid } = args;
      if (typeof eid === "string") {
        return {
          pathname: `/r/${encodeURIComponent(eid)}`,
          query: {
            dpid,
            recid,
          },
        };
      }
    }
  } catch {}
  return null;
}
