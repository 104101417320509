import { Button, ButtonProps, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import {
  ADMIN_COLOR_MAIN,
  ADMIN_COLOR_MAIN_CONTRAST,
  ADMIN_COLOR_MAIN_HOVER,
} from "../theme";

const useStyles = makeStyles<Theme, { variant: ButtonProps["variant"] }>(
  () => ({
    root: ({ variant }) =>
      variant === "contained"
        ? {
            color: ADMIN_COLOR_MAIN_CONTRAST,
            backgroundColor: ADMIN_COLOR_MAIN,
            "&:hover": {
              backgroundColor: ADMIN_COLOR_MAIN_HOVER,
            },
          }
        : {
            color: ADMIN_COLOR_MAIN,
          },
  })
);

export function AdminButton(props: ButtonProps & { component?: string }) {
  const variant = props.variant ?? "contained";
  const classes = useStyles({ variant });
  return (
    <Button
      {...props}
      variant={variant}
      className={clsx(classes.root, props.className)}
    />
  );
}
