import {
  makeStyles,
  useTheme,
  useMediaQuery,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  Typography,
  Hidden,
  Link,
  Tooltip,
  Chip,
} from "@material-ui/core";
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { BaseEventEntity } from "../entities";
import { useOpenExternalLink } from "../ExternalLink";
import { emUrl, SPACING } from "../util";
import ShareIcon from "@material-ui/icons/Share";
import CopyrightIcon from "@material-ui/icons/Copyright";
import PlaceIcon from "@material-ui/icons/Place";
import CalendarIcon from "@material-ui/icons/Event";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import NextLink from "next/link";
import { AdminButton } from "../ui/AdminButton";
import StarIcon from "@material-ui/icons/Star";
import { useUser } from "../user";
import InfoIcon from "@material-ui/icons/Info";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { useRouter } from "next/router";
import { Skeleton } from "@material-ui/lab";

const useStylesEvent = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(SPACING),
    borderLeftWidth: theme.spacing(1),
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.secondary.main,
  },
  collapsedRoot: {
    marginBottom: theme.spacing(SPACING),
  },
  collapsedRegistrationBadge: {
    marginRight: theme.spacing(0.5),
  },
  actionArea: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  mediaLarge: {
    maxHeight: 140,
    maxWidth: 300,
    float: "right",
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  mediaSmall: {
    height: 140,
  },
  icon: {
    verticalAlign: "text-bottom",
  },
}));

export const Event = React.memo(function Event({
  event,
  initiallyCollapsed,
  canEdit,
}: {
  event: BaseEventEntity;
  initiallyCollapsed: boolean;
  canEdit: "yes" | "no" | "loading";
}) {
  const classes = useStylesEvent();
  const user = useUser();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const detailsPath = `/event/${encodeURIComponent(event.slug)}`;
  const adminUrl = emUrl("event", "display", { eid: event.eid });

  const addressString = event.addresses
    .map((address) =>
      address.description?.trim() ? address.description : address.address
    )
    .join(" · ");

  const [expanded, setExpanded] = useState(!initiallyCollapsed);

  if (!expanded) {
    return (
      <Card className={classes.collapsedRoot}>
        <CardActionArea onClick={() => setExpanded(true)}>
          <CardContent>
            <Typography
              variant="h5"
              component="h2"
              style={{
                display: "flex",
                flexWrap: isSmall ? "wrap" : undefined,
              }}
            >
              {event.title}
              {(event.isUsingExternalRegistrationLink ||
                event.isRegistrationAllowed) && (
                <>
                  {isSmall ? (
                    <div style={{ flexBasis: "100%" }} />
                  ) : (
                    <div style={{ flexGrow: 1 }} />
                  )}
                  {(event.datePeriods?.[0]?.placesLeft ?? Infinity) > 0 ? (
                    <Chip
                      color="primary"
                      icon={<SupervisorAccountIcon />}
                      label={
                        event.datePeriods?.[0]?.placesLeft === null ||
                        event.datePeriods?.[0]?.placesLeft === undefined
                          ? "mit Anmeldung"
                          : event.datePeriods?.[0]?.placesLeft === 1
                          ? `1 Platz übrig`
                          : `${event.datePeriods?.[0]?.placesLeft} Plätze übrig`
                      }
                      size="small"
                      clickable={true}
                      className={classes.collapsedRegistrationBadge}
                    />
                  ) : (
                    <Chip
                      color="secondary"
                      icon={<SupervisorAccountIcon />}
                      label="keine Plätze mehr frei"
                      size="small"
                      clickable={true}
                      className={classes.collapsedRegistrationBadge}
                    />
                  )}
                </>
              )}
            </Typography>
            <Typography variant="body2" color="textPrimary" noWrap>
              {event.teaser}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  return (
    <Card className={classes.root}>
      <NextLink href={detailsPath} passHref>
        <CardActionArea className={isSmall ? undefined : classes.actionArea}>
          {isSmall && event.image && (
            <CardMedia
              image={event.image.urls["342x200"]}
              title={event.title}
              className={classes.mediaSmall}
            />
          )}
          <CardContent style={{ width: "100%" }}>
            {!isSmall && event.image && (
              <img
                src={event.image.urls["342x200"]}
                title={event.title}
                className={classes.mediaLarge}
                alt={event.image.title}
              />
            )}
            <Typography gutterBottom variant="h5" component="h2">
              {event.title}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {event.teaser}
            </Typography>

            {event.isRegistrationAllowedNow &&
              event.datePeriods?.[0]?.registration?.endHuman && (
                <Typography component="p" variant="body2" color="textSecondary">
                  <PersonAddIcon fontSize="inherit" className={classes.icon} />{" "}
                  Anmeldung möglich bis{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: event.datePeriods?.[0]?.registration?.endHuman,
                    }}
                  />
                </Typography>
              )}

            <EventDate event={event} />

            {addressString.length > 0 && (
              <Typography component="p" variant="body2" color="textSecondary">
                <PlaceIcon fontSize="inherit" className={classes.icon} />{" "}
                {addressString}
              </Typography>
            )}
            {event.isRegistrationAllowed &&
              !event.isRegistrationAllowedNowAndPlacesAreLeft && (
                <Typography component="p" variant="body2" color="textSecondary">
                  <InfoIcon fontSize="inherit" className={classes.icon} />{" "}
                  {event.registrationImpossibleMessage}
                </Typography>
              )}

            {event.image?.copyright && (
              <Typography component="p" variant="body2" color="textSecondary">
                <CopyrightIcon fontSize="inherit" className={classes.icon} />{" "}
                Bildrechte: {event.image.copyright}
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
      </NextLink>

      <CardActions>
        <RegisterButton event={event} />
        <NextLink href={detailsPath} passHref>
          <Button size="small" color="primary">
            <Hidden xsDown>Mehr </Hidden>Informationen
          </Button>
        </NextLink>
        {canEdit === "loading" && (
          <Skeleton variant="rect" width={64} height={30} />
        )}
        {canEdit === "yes" && (
          <AdminButton size="small" href={adminUrl}>
            Admin
          </AdminButton>
        )}
        <ShareButton event={event} />
        {user.permissions.TEACHER && event.featured && (
          <Tooltip title="Diese Veranstaltung wurde von der/dem Veranstaltungsersteller:in oben angepinnt">
            <StarIcon />
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
});

function RegisterButton({ event }: { event: BaseEventEntity }) {
  const openExternalLink = useOpenExternalLink();

  if (event.isRegistrationAllowed) {
    const placesLeft = event.datePeriods?.[0]?.placesLeft ?? -1;

    // If true, registration is currently impossible
    const disabled = !event.isRegistrationAllowedNowAndPlacesAreLeft;

    const btn = (
      <NextLink
        href={`/event/${encodeURIComponent(event.slug)}/register`}
        passHref
      >
        <Button
          size="small"
          color="primary"
          disabled={disabled}
          variant="contained"
          component="a"
        >
          Anmelden
          {placesLeft > -1 && (
            <Hidden xsDown> ({placesLeft} Plätze übrig)</Hidden>
          )}
        </Button>
      </NextLink>
    );

    if (disabled && event.registrationImpossibleMessage) {
      return (
        <Tooltip title={event.registrationImpossibleMessage}>
          <span>{btn}</span>
        </Tooltip>
      );
    }
    return btn;
  }

  if (event.isUsingExternalRegistrationLink) {
    return (
      <Button
        size="small"
        color="primary"
        onClick={() =>
          openExternalLink(event.externalRegistrationLink!, event.eid)
        }
        variant="contained"
      >
        Anmelden
      </Button>
    );
  }

  return null;
}

function EventDate({ event }: { event: BaseEventEntity }) {
  const classes = useStylesEvent();
  const [expanded, setExpanded] = useState(false);

  const datePeriodHTMLs = event.datePeriods
    .map((datePeriod) => datePeriod.event.human)
    .filter(Boolean);

  if (datePeriodHTMLs.length === 0) {
    return null;
  }

  const separator = " · ";

  return (
    <Typography component="p" variant="body2" color="textSecondary">
      <CalendarIcon fontSize="inherit" className={classes.icon} />{" "}
      <span dangerouslySetInnerHTML={{ __html: datePeriodHTMLs[0] }} />
      {!expanded && datePeriodHTMLs.length > 1 && (
        <>
          {separator}
          <Link
            component="span"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              setExpanded(true);
            }}
          >
            und später...
          </Link>
        </>
      )}
      {expanded && (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: ["", ...datePeriodHTMLs.slice(1)].join(separator),
            }}
          />
          {event.datePeriodsIncomplete && <>{separator} und später...</>}
        </>
      )}
    </Typography>
  );
}

const useStylesShareButton = makeStyles((theme) => ({
  root: {
    marginLeft: "auto !important",
  },
}));

export function ShareButton({ event }: { event: BaseEventEntity }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStylesShareButton();
  const router = useRouter();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);

  const eventInfoLink = `${process.env.NEXT_PUBLIC_EM_AP_URL}${
    router.basePath
  }/e/${encodeURIComponent(event.eid)}`;

  const eventFeedbackLink = `${process.env.NEXT_PUBLIC_EM_AP_URL}${
    router.basePath
  }/feedback?eid=${encodeURIComponent(event.eid)}`;

  const handleCopy = (link: string) => {
    if (
      copy(link, {
        format: "text/plain",
      })
    ) {
      enqueueSnackbar("Link kopiert");
    }
  };

  return (
    <>
      <IconButton
        size="small"
        className={classes.root}
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
      >
        <ShareIcon />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <ListItem
          button
          onClick={() => {
            handleCopy(eventInfoLink);
            handleClose();
          }}
        >
          <ListItemText
            primary="Link kopieren"
            secondary="Veranstaltungsseite"
          />
        </ListItem>
        <ListItem
          button
          onClick={handleClose}
          component="a"
          href={`mailto:?subject=${encodeURIComponent(
            event.title
          )}&body=${encodeURIComponent(`Link: ${eventInfoLink}`)}`}
        >
          <ListItemText
            primary="per E-Mail teilen"
            secondary="Veranstaltungsseite"
          />
        </ListItem>
        {event.isFeedbackAllowedNow && [
          <ListItem
            key="2"
            button
            onClick={() => {
              handleCopy(eventFeedbackLink);
              handleClose();
            }}
          >
            <ListItemText
              primary="Link kopieren"
              secondary={
                <>
                  {" "}
                  <StarIcon fontSize="inherit" />
                  Feedbackformular
                </>
              }
            />
          </ListItem>,
          <ListItem
            key="3"
            button
            onClick={handleClose}
            component="a"
            href={`mailto:?subject=${encodeURIComponent(
              event.title
            )}&body=${encodeURIComponent(`Link: ${eventFeedbackLink}`)}`}
          >
            <ListItemText
              primary="per E-Mail teilen"
              secondary={
                <>
                  {" "}
                  <StarIcon fontSize="inherit" />
                  Feedbackformular
                </>
              }
            />
          </ListItem>,
        ]}
      </Menu>
    </>
  );
}
